@import "_variables";

.hero-btn {
  background-color: red;
  padding: 5px 30px;
  background-color: transparent;
  transition: all 0.3s linear;
  color: white;
  border: 2px solid white;
  border-radius: 20px;

  &:hover {
    background-color: white;
    color: $primary;
  }
}

.services-container {
  margin: 20px 0px;
  .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    width: 90%;
    margin: auto;

    .service {
      display: flex;
      gap: 10px;
      width: 48%;
      font-size: 12px;

      h3 {
        font-size: 16px;
        // font-weight: 600;
        // color: $primary;
        // color: #00A7DE;
        margin: 0;
        background-image: linear-gradient(to left, #0060E0, #00a7de);
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .image-section {
        width: 40%;
        height: 150px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: top;
        }
      }

      .text-section {
        width: 50%;

        button {
          padding: 5px 10px;
          font-size: 10px;
          background-color: transparent;
          border: 1px solid black;
          border-radius: 20px;
          transition: all 0.3s ease;
          font-weight: 500;

          &:hover {
            background-color: $primary;
            color: white;
            border: 1px solid white;
          }
        }
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

@media (max-width: "630px") {
  .swiper-slide {
    width: 200px !important;
  }
  .services-container {
    .content {
      flex-direction: column-reverse;

      .service {
        width: 97%;
        padding: 10px;
        margin: auto;
        flex-direction: column;
        // border-top: 1px solid grey;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1),
          0px -4px 10px rgba(0, 0, 0, 0.1);

        .image-section {
          width: 80%;
          margin: auto;
        }
        .text-section {
          width: 100%;
        }
      }
    }
  }
}

/* styles for products section on landing page*/
.productsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 86px;
  width: 90%;
    margin: auto;
  @media (max-width: 976px) {
    gap: 30px;
  }
  @media (max-width: 768px) {
    display: none;
  }

  .product {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 976px) {
        flex-direction: column;
        gap: 20px;
      }
  
      &:nth-child(even){
        flex-direction: row-reverse;
      }
  
      .image-section{
        width: 52%;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: top;
          border-radius: 15px;
        }
      }
  
      .text-section{
        width: 38%;
        
      }
  
      .relative {
        position: relative;
  
        .absolute{
          position: absolute;
          top: 0;
          right: 0;
          background-color: #00A7DE;
          color: white;
          padding: 5px 10px;
          border-radius: 10px;
          font-size: 12px;
          transform: rotateY(180deg);
        }
      }
  }
}



.productsSectionMobile{
  display: none;
  @media (max-width: 768px) {
    display: block;
  }

  .product {
    background-color: #FFF;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1),
          0px -4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 90%;

    .image-section{
      width: 100%;
      // height: 250px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: top;
        border-radius: 15px;
      }
    }

    .text-section{
      width: 100%;
      h3{
        font-size: 18px;
        // font-weight: 600;
      }
    }
  }
}
